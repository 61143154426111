import React from "react";
import {
    CloseButton,
    ModalContainer,
    ModalContent,
    Overlay,
} from "./namemodal";
const NameModal = ({ closeModal }) => {
    return (
        <Overlay onClick={closeModal}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={closeModal}>&times;</CloseButton>
                <h2 style={{ fontSize: "22px" }}>
                    Big News: Scrum Companion is Rebranding!
                </h2>
                <ModalContent>
                    We're thrilled to share that Scrum Companion is rebranding
                    to Export & Count for Cloud! 🎉 This fresh name comes with a
                    new look and enhanced features, designed to bring you an
                    even better experience. Relax and enjoy as we roll out these
                    exciting updates—everything you love will still be here,
                    just with a new name and vibe!
                </ModalContent>
            </ModalContainer>
        </Overlay>
    );
};
export default NameModal;